import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { loadDynamicRoutes } from "./router/dynamicRoutes";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhcn from "element-plus/lib/locale/lang/zh-cn";

const app: any = createApp(App);
app
	.use(router)
	.use(store)
	.use(ElementPlus, {
		locale: zhcn, //2 这里使用中文
	})
	.mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}

// 调用加载动态路由的函数
loadDynamicRoutes();
